/**
 * This method is only useful as parameter for array.filter.
 * The method effectively checks whether the given value does not exist again before the given index.
 * I.e. it returns true if the given value-index-pair is the value's first occurrence in the array.
 *
 * May be used to keep the first occurrence of every value, and filter out following duplicates.
 * The method signature is exactly what is required for the callback in Array.filter.
 * Usage: `myArray.filter(isFirstOccurrenceOfValueInArray)` to create an array with unique values.
 *
 * @see https://stackoverflow.com/questions/1960473/get-all-unique-values-in-a-javascript-array-remove-duplicates
 *
 * @param value an entry of the array (for which we want to check if it's the first element with this value in the array)
 * @param index the index of the value in the array
 * @param array the array
 * @returns whether this value is the first one in this array
 */
export const isFirstOccurrenceOfValueInArray = <T>(value: T, index: number, array: T[]): boolean =>
  array.indexOf(value) === index

// #region Timeline handling

/**
 * Interface to define steps/phases on a timeline
 */
export interface TimelinePhase {
  /** day when a phase starts */
  startDate: string
  /** number of the phase, to be able to compare (e.g. if (phase >= 2)... */
  phase: number
}


/**
 * calculates from a given Timeline the latest phase in which todays date is lying in
 *
 * @param timeline an array of timeline
 * @returns
 */
export const getLatestTimelinePhase = (timeline: TimelinePhase[]): TimelinePhase => {
  let latestDate: TimelinePhase = { startDate: "0", phase: null }

  // find out this date in the timeline, that is the newest/latest, but is in the past compared to today
  timeline.forEach(step => {
    const today = new Date()
    const dateToCheck = new Date(step.startDate)
    if (dateToCheck > new Date(latestDate.startDate) && today >= dateToCheck) {
      latestDate = step
    }
  })

  return latestDate
}
// #endregion