/**
 * Types of unhandled unexpected errors
 */
export declare type UnhandledUnexpectedErrorType = 'server' | 'i18n' | 'fetching-url'

/**
 * Log unhandled unexpected errors, e.g. on console or via Sentry.
 *
 * @todo add Sentry handling, including a setting/environment flag
 */
export const logUnhandledUnexpectedError = (errorMsg: string, type: UnhandledUnexpectedErrorType): void => {
  // eslint-disable-next-line no-console
  console.log("Unhandled unexpected error of type '" + type + "': " + errorMsg)
}