import { Action } from "redux"
import { all, call, put, takeEvery } from "redux-saga/effects"
import { withCallback } from "redux-saga-callback"

import client from "@api/client"
import { IModel } from "@api/schema"
import { TransitionDTO } from "@api/schema-dto"
import { IFormikActions, newSingleEntityUsecaseRequestRunningAction, newSingleEntityUsecaseRequestSuccessAction, updateModelSuccessAction } from "@redux/helper/actions"
import { showErrorsInTestEnvironment } from "@redux/helper/sagas"
import { UNKNOWN_REQUEST_ERROR } from "@redux/lib/constants"
import { SubmissionError } from "@services/submissionError"
import { entityTypeFromIModelOrIRI } from "@services/util"

/*
 * @todo multi refactor transition saga? Could include locking and unlocking
 * => for details @see https://futureprojects.atlassian.net/browse/FCP-1553
 */

/* @†odo multi maybe move to generalSaga */

/**
 * This enum defines the usecases and its usecase key for "transitions".
 */
export enum TransitionUsecases {
  /**
   * type (and usecase key) for transitioning an entity from one state to another
   */
  TransitionEntity = "_usecase_transition",
}

// #region generic transition action
/**
 * Type of an Action for triggering a transition of an entity.
 * @template E type of the Entity for which the transition is prepared
 * @template TransitionEnum list of transitions that may be performed
 */
interface ITransitionAction<E, TransitionEnum> extends Action {
  /** formik actions to interact with the triggering form */
  actions: IFormikActions
  /** entity to be transitioned */
  entity: E
  /** transition to be performed on the entity */
  transition: TransitionDTO<TransitionEnum>
  /** type of the action, to be used as usecase key */
  type: TransitionUsecases
}

/**
 * Action for triggering a transition.
 *
 * @param entity Entity to be transitioned.
 * @param transition Transition to be performed on that entity.
 * @param actions FormikActions to respond to the calling form
 *
 * @template E type of the entity for which the action should be performed
 * @template Transition transition to be performed on the entity
 * @returns an Action to be dispatched
 */
export const entityTransitionAction = <E, Transition>(
  entity: E,
  transition: TransitionDTO<Transition>,
  actions: IFormikActions
): ITransitionAction<E, Transition> => ({
  actions,
  entity,
  transition,
  type: TransitionUsecases.TransitionEntity,
})

// #endregion

// #region generic saga
export function* transitionWatcherSaga(): any {
  yield all([
    takeEvery(TransitionUsecases.TransitionEntity, withCallback(entityTransitionSaga)),
  ])
}

function* entityTransitionSaga<E extends IModel, Transition>(action: ITransitionAction<E, Transition>) {
  const { onSuccess, setErrors, setSubmitting } = action.actions || {}

  // special (non-default) sagas for special (non-default) actions use their special usecaseKey (identical to action.type)
  const usecaseKey = action.type

  const entityType = entityTypeFromIModelOrIRI(action.entity)

  try {
    yield put(newSingleEntityUsecaseRequestRunningAction(entityType, usecaseKey))

    const entity: E = yield call(client.transitionEntity, action.entity, action.transition)

    yield put(updateModelSuccessAction(entityType, entity))

    yield put(newSingleEntityUsecaseRequestSuccessAction(entityType, usecaseKey, entity))

    if (setSubmitting) {
      yield call(setSubmitting, false)
    }
    if (onSuccess) {
      yield call(onSuccess, entity)
    }

    return entity
  } catch (err) {

    const errorMessage = err instanceof Error ? err.message : UNKNOWN_REQUEST_ERROR
    showErrorsInTestEnvironment("transitionSaga", errorMessage, action, err)

    if (setErrors) {
      if (err instanceof SubmissionError) {
        // errorHandling: setErrors is a function from FormikHelpers to set errors on a Formik-form
        yield call(setErrors, err.errors)
      } else {
        yield call(setErrors, { error: errorMessage })
      }
    }

    // if an error occurred: signalize that the currentScopeType-request has failed with the error message
    yield put(newSingleEntityUsecaseRequestRunningAction(entityType, usecaseKey, errorMessage))

    if (setSubmitting) {
      yield call(setSubmitting, false)
    }

    return null
  }
}
// #endregion